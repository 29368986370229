export const availableModels: { [modelKey: string]: { [modelYear: string]: string[] } } = {
  "smart #1": {
    "2022": [
      'CoC_CEM',
      'CoC_PEPS Antenna',
      'CoC_PCBA',
      'CoC_BNCM',
      'CoC_TCAM',
      'CoC_DHU',
      'CoC_TPMS',
      'CoC_FLR',
      'CoC_SOD',
      'CoC_Wireless Charger',
      'CoC_FM2DAB AMP'
    ]

  }, "smart #3": {
    "2023": [
      'CoC_CEM',
      'CoC_PEPS Antenna',
      'CoC_PCBA',
      'CoC_BNCM',
      'CoC_TCAM',
      'CoC_DHU',
      'CoC_TPMS',
      'CoC_MCR1_CR-FR',
      'CoC_Wireless Charger',
      'CoC_FM2DAB AMP'
    ]
  }
}
