import {Component, OnInit} from '@angular/core';
import {availableModels} from "../availableModels";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {LocaleData} from "../model/localeData";
import {Part} from "../model/part";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor(private translate: TranslateService) {
  }

  selectedModel!: string;
  selectedYear!: string;

  availableModels = availableModels;
  availableModelYears: string[] = []
  localeData?: LocaleData;

  filteredParts: Part[] = []


  ngOnInit() {
    this.updateLocales();
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.updateLocales();
      });
  }

  updateLocales() {
    this.translate.get(['availableParts', 'legalNotesLink', 'dataProtectionLink', 'contactLink'])
      .subscribe((link: LocaleData) => {
        this.localeData = link;
        this.applyFilters();
      });
  }

  applyModelFilter(selectedValue: any) {
    this.selectedYear = '';
    (document.getElementById('years') as any).selectedIndex = 0;
    if (selectedValue.target.selectedIndex > 0) {
      this.availableModelYears = Object.keys(this.availableModels[this.selectedModel]);
    }
    this.applyFilters();
  }

  applyFilters() {
    this.filteredParts = []
    if (this.selectedModel && this.selectedYear) {
      let partKeys: string[] = (this.availableModels[this.selectedModel][this.selectedYear])
      for (let partKey of partKeys) {
        if (this.localeData?.availableParts[partKey]) {
          this.filteredParts.push(this.localeData.availableParts[partKey])
        } else {
          console.error("No Part locales")
        }
      }
    }
    if ((document.getElementById('models') as any).selectedIndex <= 0) {
      (document.getElementById('models') as any).selectedIndex = 0;
      (document.getElementById('years') as any).selectedIndex = 0;
    }
  }

  clearFilters() {
    (document.getElementById('models') as any).selectedIndex = 0;
    (document.getElementById('years') as any).selectedIndex = 0;
    this.selectedModel = '';
    this.selectedYear = '';
    this.filteredParts = []
  }

  getDocument(s: string): string {
    // At the moment there is only one set of documents (en).
    //return "../../assets/pdfs/".concat(this.translate.currentLang, "/", s);
    return "../../assets/pdfs/en/".concat(s);
  }
}

