<p class="intro-para" [translate]="'introduction'">
  The radio equipment directive 2014/53/EU (RED) establishes a regulatory framework for placing radio equipment on the
  market. It ensures a single market for radio equipment by setting essential requirements for safety and health,
  electromagnetic compatibility, and the efficient use of the radio spectrum.
  All radio equipment within the scope of this directive that is placed on the EU market must have been compliant with
  the directive from 13 June 2017.
  Our vehicles have components that transmit and receive radio waves and are therefore subject to European directive
  2014/53/EU or similar directives globally.
  Our suppliers have declared that their components conform to these directives. For the full text of their
  declaration, select the vehicle and the component in which you are interested to see further details.
</p>

<div class="filter-container">
  <div class="title" [translate]="'filter'">Filter</div>
  <div class="filters row">
    <div class="col-md-4 col-sm-6 col-12">
      <select name="models" id="models" class="vehicle-model dropdownContent" [(ngModel)]="selectedModel" (change)="applyModelFilter($event)">
        <option class="overlayOpt" value="" [translate]="'vehicleSelection'">Select Vehicle Model</option>
        <option class="overlayOpt" *ngFor="let model of availableModels | keyvalue" [value]="model.key">
          {{model.key}}
        </option>
      </select>
    </div>
    <div class="col-md-4 col-sm-6 col-12">
      <select name="years" id="years" class="vehicle-year dropdownContent" [(ngModel)]="selectedYear" (change)="applyFilters()">
        <option class="overlayOpt" value="" [translate]="'yearSelection'">Select Year</option>
        <option class="overlayOpt" *ngFor="let year of availableModelYears" [value]="year">
          {{year}}
        </option>
      </select>
    </div>
    <div class="col-md-2 col-sm-3 col-6 text-center">
      <div class="clearBtn" (click)="clearFilters()" [translate]="'clearAll'">
        Clear All
      </div>
    </div>
    <div class="col-sm-3 sm-device"></div>
  </div>
</div>
<div *ngIf="filteredParts.length">
  <div class="table-view">
    <table className="table" style="color:white;font-size:12px">
      <thead>
      <tr>
        <th>Part Name</th>
        <th>Link to Document</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let part of filteredParts">
        <td>
          {{part.name}}
        </td>
        <td style="font-weight: 700;">
          <a *ngIf="part.pdfFileName" style="color: white;text-decoration-color: #d7e600;" [href]="getDocument(part.pdfFileName)"
             target="_blank">{{part.pdfDisplayName}}</a><br><br>
          <a *ngIf="part.pdfFileNameUkca" style="color: white;text-decoration-color: #d7e600;"
             [href]="getDocument(part.pdfFileNameUkca)" target="_blank">{{part.pdfDisplayNameUkca}}</a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<footer style="position: relative;height:10vh;background-color: #141413;"
        [ngClass]="filteredParts.length?'':'fix-down'">
  <div class="footer-container">
    <div class="footer-links">
      <a class="smart-logo" target="_blank">smart |&nbsp;</a>
      <a style="margin-right:10px ;text-decoration-line:underline;color: white;" [translate]="'legalNotes'"
         [href]="localeData?.legalNotesLink" target="_blank"> Legal Notes</a>
      <a style="margin-right:10px ;text-decoration-line:underline;color: white;" [translate]="'dataProtection'"
         [href]="localeData?.dataProtectionLink" target="_blank" > Data Protection</a>
      <a style="margin-right:10px ;text-decoration-line:underline;color: white;" [translate]="'contact'"
         [href]="localeData?.contactLink" target="_blank">Contact</a>
    </div>

    <div>
      <a target="_blank" style="padding-right:5px" href="https://twitter.com/smart_worldwide">

        <svg xmlns="http://www.w3.org/2000/svg" id="Icon_awesome-twitter" data-name="Icon awesome-twitter"
             width="20" height="20" viewBox="0 0 36 29.239">
          <path id="Icon_awesome-twitter-2" data-name="Icon awesome-twitter"
                d="M32.3,10.668c.023.32.023.64.023.959,0,9.754-7.424,20.992-20.992,20.992A20.85,20.85,0,0,1,0,29.307a15.263,15.263,0,0,0,1.782.091,14.776,14.776,0,0,0,9.16-3.152,7.391,7.391,0,0,1-6.9-5.117,9.3,9.3,0,0,0,1.393.114,7.8,7.8,0,0,0,1.942-.251,7.379,7.379,0,0,1-5.916-7.241V13.66A7.431,7.431,0,0,0,4.8,14.6,7.389,7.389,0,0,1,2.513,4.728a20.972,20.972,0,0,0,15.213,7.721,8.329,8.329,0,0,1-.183-1.69A7.385,7.385,0,0,1,30.312,5.711a14.526,14.526,0,0,0,4.683-1.782,7.358,7.358,0,0,1-3.244,4.066A14.791,14.791,0,0,0,36,6.853a15.86,15.86,0,0,1-3.7,3.815Z"
                transform="translate(0 -3.381)" fill="#969da3" />
        </svg>
      </a>&nbsp;&nbsp;
      <a target="_blank" style="padding-right:5px" href="https://www.facebook.com/smart">
        <svg xmlns="http://www.w3.org/2000/svg" id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f"
             width="20" height="20" viewBox="0 0 19.281 29.24">
          <path id="Icon_awesome-facebook-f-2" data-name="Icon awesome-facebook-f"
                d="M19.627,16.448l1-5.292H14.375V7.722c0-1.448.873-2.859,3.673-2.859h2.842V.358A42.451,42.451,0,0,0,15.846,0C10.7,0,7.332,2.535,7.332,7.123v4.033H1.609v5.292H7.332V29.24h7.043V16.448Z"
                transform="translate(-1.609)" fill="#969da3" />
        </svg>
      </a>&nbsp;&nbsp;
      <a target="_blank" style="padding-right:5px" href="https://www.instagram.com/smart_worldwide/">
        <svg xmlns="http://www.w3.org/2000/svg" id="Icon_awesome-instagram" data-name="Icon awesome-instagram"
             width="20" height="20" viewBox="0 0 29.34 29.34">
          <path id="Icon_awesome-instagram-2" data-name="Icon awesome-instagram"
                d="M14.668,9.385a7.522,7.522,0,1,0,7.521,7.522A7.51,7.51,0,0,0,14.668,9.385Zm0,12.413a4.891,4.891,0,1,1,4.889-4.891A4.9,4.9,0,0,1,14.668,21.8ZM24.251,9.078A1.754,1.754,0,1,1,22.5,7.323,1.75,1.75,0,0,1,24.251,9.078Zm4.981,1.781a8.684,8.684,0,0,0-2.369-6.148,8.737,8.737,0,0,0-6.146-2.37c-2.422-.137-9.681-.137-12.1,0A8.724,8.724,0,0,0,2.467,4.7,8.712,8.712,0,0,0,.1,10.852C-.04,13.274-.04,20.535.1,22.957A8.684,8.684,0,0,0,2.467,29.1a8.748,8.748,0,0,0,6.146,2.37c2.422.137,9.681.137,12.1,0a8.68,8.68,0,0,0,6.146-2.37,8.741,8.741,0,0,0,2.369-6.148c.137-2.422.137-9.676,0-12.1ZM26.1,25.556a4.951,4.951,0,0,1-2.788,2.789c-1.931.766-6.513.589-8.647.589s-6.722.17-8.647-.589a4.951,4.951,0,0,1-2.788-2.789c-.766-1.931-.589-6.514-.589-8.648s-.17-6.724.589-8.648A4.951,4.951,0,0,1,6.021,5.47c1.931-.766,6.513-.589,8.647-.589s6.722-.17,8.647.589A4.951,4.951,0,0,1,26.1,8.259c.766,1.931.589,6.514.589,8.648S26.869,23.631,26.1,25.556Z"
                transform="translate(0.005 -2.238)" fill="#969da3" />
        </svg>
      </a>&nbsp;&nbsp;
      <a target="_blank" href="https://www.youtube.com/user/smart">
        <svg xmlns="http://www.w3.org/2000/svg" id="Icon_metro-youtube" data-name="Icon metro-youtube"
             width="20" height="20" viewBox="0 0 29.34 29.34">
          <path id="Icon_metro-youtube-2" data-name="Icon metro-youtube"
                d="M10.971,1.928,8.989,5.86,7.006,1.928H4.478L8.051,8.155l.021-.012v4.788H9.906V8.142l.021.012L13.5,1.928ZM16.324,5.6a.929.929,0,0,1,.917.917V10.18a.917.917,0,1,1-1.834,0V6.512a.929.929,0,0,1,.917-.917Zm0-1.834a2.759,2.759,0,0,0-2.751,2.751V10.18a2.751,2.751,0,0,0,5.5,0V6.512a2.759,2.759,0,0,0-2.751-2.751Zm8.252,0v7.219c-.8.667-1.834,1.07-1.834-1.308V3.762H20.908v6.284h0c.028,1.516.348,4.245,3.665,2.026v.859h1.834V3.762H24.576ZM27.326,22.1a.918.918,0,0,0-.917.917v.917h1.834v-.917a.918.918,0,0,0-.917-.917Zm-8.252.917V27.83c.621.621,1.834.688,1.834-.229V23.3C20.908,22.386,19.991,22.1,19.074,23.016Zm12.686-4.426a3.813,3.813,0,0,0-3.749-3.677c-2-.1-6.574-.149-10.593-.149s-8.919.05-10.919.149a3.813,3.813,0,0,0-3.749,3.677c-.12,2.152-.18,2.933-.18,4.009s.06,2.69.18,4.843A3.813,3.813,0,0,0,6.5,31.119c2,.1,6.9.149,10.919.149s8.594-.05,10.593-.149a3.813,3.813,0,0,0,3.749-3.677q.15-2.69.15-4.843c0-1.435-.05-2.215-.15-4.009ZM8.072,29.434H6.238V20.266H4.4V18.432h5.5v1.834H8.072Zm7.335,0H13.573v-.858c-3.486,1.957-3.636.063-3.665-1.453h0V22.1h1.834v5.052c0,1.108,1.034,1,1.834.333V22.1h1.834Zm7.335-1.86c0,1.917-1.963,2.476-3.667.985v.875H17.241v-11h1.834v2.98c1.834-1.834,3.667-1.146,3.667.688Zm7.335-3.641v.917H26.409v1.834a.917.917,0,0,0,1.834,0v-.917h1.834v.917a2.751,2.751,0,1,1-5.5,0V23.016a2.751,2.751,0,0,1,5.5,0v.917Z"
                transform="translate(-2.571 -1.928)" fill="#969da3" />
        </svg>
      </a>
    </div>
  </div>
</footer>
